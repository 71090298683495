<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ $t("m.url.title") }}</v-card-title>
      <v-card-subtitle>{{ $t("m.url.subtitle") }}</v-card-subtitle>
      <v-card-text v-if="isMasterUrlEmpty">
        <v-row justify="center">
          <v-progress-circular
            color="primary"
            :indeterminate="isMasterUrlEmpty"
          ></v-progress-circular>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <!-- <v-col> -->
        <!-- <v-row justify="center">
            <VueQr :dotScale=".5" :text="masterUrl" />
        </v-row>-->
        <v-row justify="center">
          <v-col cols="auto">
            <a :href="masterUrl">{{ masterUrl }}</a>
          </v-col>
        </v-row>
        <!-- </v-col> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { shrinkUrl } from '@/helpers/referral'
import { addQRCode } from '@/helpers/firebase-functions'

// import VueQr from 'vue-qr'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  data: () => ({
    masterUrl: ''
  }),
  computed: {
    appSociallyColor () {
      return '#FFD54F'
    },
    isMasterUrlEmpty () {
      return this.masterUrl === ''
    }
  },
  async created () {
    const ownerId = this.$route.params.ownerId
    const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

    const absoluteUrl = window ? window.location.origin : ''
    const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: 'ownersite', campaignType: 'ownersite' })
    this.masterUrl = await shrinkUrl(`${absoluteUrl}/jobs/${ownerId}/${ownerScenarioId}?campaignInfo=${campaignInfo}`)

    const qrCode = {
      label: this.$i18n.t('m.url.label'),
      url: this.masterUrl,
      enabled: true,
      count: 0,
      qrCodeId: 'ownersite',
      qrCodeType: 'ownersite'
    }
    addQRCode({ ownerId, scenarioId: ownerScenarioId, qrCode })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_OWNER_SITE_BOT_URL)
  }
}
</script>
